<template>
    <div class="d-flex flex-column justify-space-around text-center align-center login">
        <v-card class="pa-15" height="380px">
            <img
                height="100px"
                width="100px"
                src="@/assets/IE_logo_mark.png"
                alt="Illuminate Education"
            />
            <div>
                <div class="mb-8">Welcome to Console for Illuminate Education!</div>
                <div>
                    <v-autocomplete
                        v-model="client"
                        prepend-inner-icon="fal fa-search fa-sm"
                        return-object
                        outlined
                        cache-items
                        placeholder="Find Your District"
                        :items="clients"
                        item-text="displayName"
                        item-value="id"
                        :search-input.sync="search"
                        :loading="fetching"
                        class="district-select"
                        :hide-no-data="fetching"
                        :no-data-text="
                            search && search.length > 2
                                ? 'No districts available by that name'
                                : 'Enter district name (min 3 characters)'
                        "
                    >
                        <template #item="{ item }">
                            <v-list-item :href="link(item)">
                                {{ item.displayName }}
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </div>
            </div>
        </v-card>
        <div class="footer-logo">
            <a :href="employeeLogin">
                <v-img
                    class="employee-login"
                    src="@/assets/IE_login_logo_footer.svg"
                    alt="Illuminate Education"
                />
            </a>
        </div>
        <div class="copyright">
            &#169; Copyright {{ copyrightYear }}
            <img height="20px" width="20px" src="@/assets/IE_logo_mark.png" />
            Illuminate Education, Inc. All Rights Reserved.
        </div>
    </div>
</template>

<script>
import router from '@/router'
import { mapGetters, mapActions } from 'vuex'
import { ieAuthLoginRoute } from '@/helpers/router'
import { errorSnackbar } from '@/helpers/snackbar'

export default {
    data: () => ({ search: null, client: null, loginError: null }),
    computed: {
        ...mapGetters({
            hasDistrict: 'hasDistrict',
            hasRole: 'auth/hasRole',
            hasUser: 'auth/hasUser',
            clients: 'client/getItems',
            fetching: 'client/getFetching',
        }),
        copyrightYear() {
            return new Date().getFullYear()
        },
        employeeLogin() {
            //Avoid setting the referrer to /login
            const referrer =
                router.history._startLocation.indexOf('/login') !== 0
                    ? router.history._startLocation
                    : '/'

            const buff = Buffer.from(JSON.stringify({ referrer }))
            const browserState = buff.toString('base64')
            const appTLD = process.env.VUE_APP_APP_TLD
            const subdomain = process.env.VUE_APP_APP_SUBDOMAIN

            return `https://${subdomain}.illuminateed.${appTLD}/api/oauth/connect/renaissance_azure?state=${browserState}`
        },
    },
    watch: {
        async search(val) {
            if (!val || val.length <= 2) {
                return
            }

            clearTimeout(this.searchDebounce)
            this.searchDebounce = setTimeout(
                async () => await this.getPublicClients(`search[name]=${val}`),
                500
            )
        },
        async loginError(error) {
            if (!error) {
                return
            }

            await errorSnackbar({ message: 'Login Error', subtext: error })
        },
    },
    async mounted() {
        this.loginError = this.$route.query.loginError
    },
    methods: {
        ...mapActions({
            getPublicClients: 'client/getPublicClients',
        }),
        link(item) {
            return ieAuthLoginRoute(item.id)
        },
    },
}
</script>

<style lang="scss">
.v-main__wrap > .container {
    //slight hack... remove padding for main container. This shouldn't
    //load for the main page because the login page always redirects

    padding: 0;
}
</style>

<style lang="scss" scoped>
.login::v-deep {
    background-image: url('~@/assets/IE_login_bg.svg');
    background-repeat: repeat;
    height: 100vh;
}
.footer-logo {
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.copyright {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #ffffff;
}
.district-select::v-deep .fa-sm {
    font-size: 0.875em;
}
</style>
